import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/Layout"
import Mailto from 'reactv16-mailto';
import { Jumbotron, Container, Row, Col } from "react-bootstrap"

import { Helmet } from 'react-helmet'

import Zimbra_logo from "../images/Zimbra_logo.png"
import "../styles/Globals.scss"


export default function Home() {
  return (
    <Layout>
            
      <Helmet defer={false}>
        <title defer={false}>Zimbra Mailserver | WH Serverpark</title>
      </Helmet>

      <Jumbotron fluid>
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }}>
              <h1>Zimbra Groupware Lösung</h1>
              <p>
                Suche Sie eine komfortable, schnelle und sichere Groupware-Lösung?
                <br></br>
                Dann Sie mit Zimbra genau richtig.
              </p>
              <p>
              <Mailto className="btn btn-primary" email='info@wh-serverpark.com' obfuscate={true}  headers={{subject:'Zimbra Hosting'}}>Kontakt aufnehmen</Mailto>
              </p>
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 4, order: 2 }}
              className="text-md-right"
            >
              <img src={Zimbra_logo} alt="" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      <Container>
        <Row>
          <Col lg={10}>
            <p>
              Zimbra bietet als Groupware Lösung natürlich persönliche E-Mail, Adressbuch und Kalender. Aber nicht nur, denn Sie können alle dies auch mit anderen Zimbra User teilen und so einfach und zuverlässig Gruppen- E-Mail Ordner, Kalender und Adressbücher einrichten und nutzen.
            </p>
            <p>
              Mit der Zimbra Weboberfäche haben Sie alle Features im Blick und können diese einfach nutzen.
            </p>
            <p>
              Eine Groupware Lösung ist immer ein individuelle Sache, gerne beantworten wir Ihre Anfragen direkt in einem
              persönlichen Gespräch oder per <Mailto email='info@wh-serverpark.com' obfuscate={true}  headers={{subject:'Zimbra Hosting'}}>E-Mail</Mailto>.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
